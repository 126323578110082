/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'rebass';

const ThemeText = ({
  children,
  ...rest
}) => (
  <Text {...rest}>{children}</Text>
);

ThemeText.propTypes = {
  children: PropTypes.node,
};

ThemeText.defaultProps = {
  children: null,
};

export default ThemeText;
