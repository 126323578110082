import React from 'react';
import PropTypes from 'prop-types';
import { useResource, useInvalidator } from 'rest-hooks';

import HotelResource from '../resources/Hotel';

export const HotelContext = React.createContext();

export const HotelProvider = (props) => {
  const { children, hotelId } = props;
  const hotel = useResource(HotelResource.detail(), { id: hotelId });
  const invalidateHotel = useInvalidator(HotelResource.detail());
  const refresh = () => invalidateHotel({ id: hotelId });

  return (
    <HotelContext.Provider
      value={{ hotel, refresh }}
    >
      { children }
    </HotelContext.Provider>
  );
};

const propTypes = {
  children: PropTypes.node.isRequired,
  hotelId: PropTypes.string,
};

const defaultProps = {
  hotelId: null,
};

HotelProvider.propTypes = propTypes;
HotelProvider.defaultProps = defaultProps;

export const { Consumer: HotelConsumer } = HotelContext;
