/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy } from 'react';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<div>Loading...</div>}>
    <Component {...props} />
  </Suspense>
);

const App = Loadable(lazy(() => import('./App')));

const routes = [
  {
    path: '/hotels/:id',
    element: <App />,
  },
  {
    path: 'hotels/:id/surname',
    element: <App />,
  },
  {
    path: 'hotels/:id/quickinput',
    element: <App />,
  },
];

export default routes;
