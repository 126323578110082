import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { Card } from '../theme/components';

const ErrorCard = (props) => {
  const { error } = props;
  const errorString = `${error?.response?.statusText || 'Unknown error'}`;
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
      bg="card"
      sx={{ overflow: 'scroll' }}
    >
      <Box
        maxWidth={900}
        width="100%"
        sx={{
          margin: '0 auto',
        }}
      >
        <Card
          title={errorString}
        />
      </Box>
    </Flex>
  );
};

ErrorCard.propTypes = {
  error: PropTypes.shape({
    status: PropTypes.string.isRequired,
    response: PropTypes.shape({
      statusText: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ErrorCard;
