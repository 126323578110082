import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Flex, Box } from 'rebass';
import { Input } from '@rebass/forms';

import Icon from './Icon';

const DatePickerInput = forwardRef(
  ({
    value, onClick, onBlur, error,
  }, ref) => (
    <Flex
      alignItems="center"
      height="48px"
      onClick={onClick}
      onBlur={onBlur}
      sx={{
        borderColor: error ? 'danger' : 'secondary',
        borderRadius: '6px',
        borderStyle: 'solid',
        borderWidth: '1px',
      }}
    >
      <Input
        flexGrow={1}
        ref={ref}
        value={value}
        height="100%"
        sx={{
          borderWidth: 0,
        }}
      />
      <Box sx={{
        px: 2,
        float: 'right',
        fontSize: 3,
        color: 'primary',
        opacity: 0.8,
      }}
      >
        <Icon name="calendar" />
      </Box>
    </Flex>
  ),
);

DatePickerInput.propTypes = {
  value: PropTypes.oneOfType(
    [PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number, PropTypes.object],
  ),
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  value: null,
  onClick: () => {},
  onBlur: () => {},
  error: null,
};

export default DatePickerInput;
