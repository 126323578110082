import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './typeahead.css';
import { CacheProvider, NetworkErrorBoundary } from 'rest-hooks';
import { BrowserRouter, useRoutes } from 'react-router-dom';
// import { AuthProvider } from './contexts/AuthContext';

// import App from './App';
import reportWebVitals from './reportWebVitals';
import routes from './routes';
import ErrorCard from './views/ErrorCard';

const App = () => {
  const element = useRoutes(routes);
  return element;
};

ReactDOM.render(
  <React.StrictMode>
    <CacheProvider>
      <BrowserRouter>
        <NetworkErrorBoundary fallbackComponent={ErrorCard}>
          {/* <AuthProvider> */}
          <App />
          {/* </AuthProvider> */}
        </NetworkErrorBoundary>
      </BrowserRouter>
    </CacheProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
