/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';

import Text from './Text';

const Tooltip = ({
  tooltip,
}) => {
  const [tooltipActive, setTooltip] = useState(false);

  const handleOnMouseOver = () => {
    setTooltip(true);
  };

  const handleOnMouseOut = () => {
    setTooltip(false);
  };

  return (
    <div
      onMouseOver={handleOnMouseOver}
      onMouseOut={handleOnMouseOut}
    >
      <b style={{ fontFamily: 'Roboto', fontWeight: 700 }}>?</b>
      {tooltipActive && (
        <Box
          style={{
            position: 'absolute',
            right: 0,
            maxWidth: '800px',
            zIndex: 1000,
            background: 'white',
            borderRadius: 5,
            border: '1px solid #a0a0a0',
          }}
          className="p-2"
        >
          <Text
            sx={{
              alignItems: 'center',
              lineHeight: '24px',
              fontFamily: 'Roboto',
              fontSize: '13px',
            }}
          >
            {tooltip}
          </Text>
        </Box>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  tooltip: PropTypes.string,
};

Tooltip.defaultProps = {
  tooltip: null,
};

export default Tooltip;
