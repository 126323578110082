/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {
  Flex,
  Box,
} from 'rebass';
import {
  Label,
} from '@rebass/forms';
import defaultTheme from '../index';
import { HotelContext } from '../../contexts/HotelContext';

const DatePickerInput = (props) => {
  const {
    label,
    id,
    name,
    minDate,
    maxDate,
    value,
    onChange,
    error,
    required,
  } = props;
  const { hotel } = useContext(HotelContext);
  const createTheme = (inputHotel) => {
    const currentTheme = {
      ...defaultTheme,
      colors: {
        primary: inputHotel.colors?.colorMain || defaultTheme.colors.primary,
        secondary: inputHotel.colors?.colorSecondary || defaultTheme.colors.secondary,
        active: inputHotel.colors?.colorActive || defaultTheme.colors.active,
        disabled: inputHotel.colors?.colorDisabled || defaultTheme.colors.disabled,
        background: inputHotel.colors?.colorBackground || defaultTheme.colors.background,
        card: inputHotel.colors?.colorCard || defaultTheme.colors.card,
        danger: inputHotel.colors?.colorError || defaultTheme.colors.danger,
        light: inputHotel.colors?.colorLight || defaultTheme.colors.light,
        dark: inputHotel.colors?.colorDark || defaultTheme.colors.dark,
        warning: '',
        success: '',
      },
    };
    return currentTheme;
  };

  const theme = createTheme(hotel);
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Box>
        <Flex sx={{ marginBottom: '8px' }}>
          <Label
            htmlFor={name}
            style={{
              lineHeight: '24px',
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontWeight: 'bold',
              width: 'auto',
            }}
          >
            {label}
            {required && '*'}
          </Label>
          {error && (
          <Box
            flexGrow={1}
            sx={{
              textAlign: 'right',
              color: 'danger',
              lineHeight: '24px',
              fontFamily: 'Roboto',
              fontSize: '14px',
            }}
          >
            {error}

          </Box>
          )}
        </Flex>
        <DatePicker
          width="100%"
          minDate={minDate}
          maxDate={maxDate}
          id={id}
          name={name}
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              style={{
                width: '100%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: (error ? theme.colors.danger : theme.colors.secondary),
                borderRadius: '6px',
                fontFamily: theme.fonts.input,
                height: '46px',
                color: 'dark',
                '&::placeholder': {
                  fontSize: '16px',
                  fontFamily: theme.fonts.input,
                  color: theme.colors.dark,
                  opacity: 0.5,
                },
                marginBottom: '8px',
              }}
              {...params}
              inputProps={{ ...params.inputProps, placeholder: '' }}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};

DatePickerInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minDate: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  maxDate: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  value: PropTypes.oneOfType(
    [PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number, PropTypes.object],
  ),
  onChange: PropTypes.func,
  error: PropTypes.string,
  required: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  minDate: null,
  maxDate: null,
  value: null,
  onChange: () => {},
  error: null,
  required: false,
};

export default DatePickerInput;
