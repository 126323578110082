/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Flex, Box } from 'rebass';
import { Label, Input } from '@rebass/forms';

const Autocomplete = (props) => {
  const {
    id,
    name,
    label,
    error,
    onChange,
    onBlur,
    options,
    value,
  } = props;

  const handleChange = (values) => {
    const newValue = Array.isArray(values) && values.length ? values[0] : values;
    onChange(newValue);
  };

  const { required, ...otherProps } = props;
  const innerProps = {
    ...otherProps,
    sx: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: (error ? 'danger' : 'secondary'),
      borderRadius: '6px',
      fontFamily: 'input',
      height: '48px',
      color: 'dark',
      '&::placeholder': {
        fontSize: '16px',
        fontFamily: 'input',
        color: 'dark',
        opacity: 0.5,
      },
    },
  };

  const inputComponent = ({
    inputRef,
    referenceElementRef,
    ...inputProps
  }) => (
    <Input
      {...innerProps}
      {...inputProps}
      ref={(node) => {
        inputRef(node);
        referenceElementRef(node);
      }}
    />
  );

  // const menuComponent = (results, menuProps, state) => {
  //   console.log(menuProps);
  //   console.log(state);
  //   return (
  //     <Box {...menuProps} role="listbox">
  //       {results.map((option, index) => (
  //         <a href="#" role="option" key={`${id}-item-${index}`} position={index}>
  //           <Text>{option.name}</Text>
  //         </a>
  //       ))}
  //     </Box>
  //   );
  // };

  // const menuItemComponent = (option, unkn, index) => {
  //   console.log(unkn);
  //   return (
  //     <Text>{option.name}</Text>
  //   );
  // };

  return (
    <Box>
      <Flex sx={{ marginBottom: '8px' }}>
        <Label
          htmlFor={name}
          style={{
            lineHeight: '24px',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 'bold',
            width: 'auto',
          }}
        >
          {label}
          {required && '*'}
        </Label>
        {error && (
          <Box
            flexGrow={1}
            sx={{
              textAlign: 'right',
              color: 'danger',
              lineHeight: '24px',
              fontFamily: 'Roboto',
              fontSize: '14px',
            }}
          >
            {error}
          </Box>
        )}
      </Flex>
      <Typeahead
        style={{
          fontFamily: 'Roboto',
        }}
        id={id}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        // selected={value ? [value] : null}
        selected={value ? [value] : []}
        labelKey="name"
        options={options}
        renderInput={inputComponent}
        // renderMenu={menuComponent}
        // renderMenuItemChildren={menuItemComponent}
      />
    </Box>
  );
};

Autocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
  minChars: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.shape({
    name: PropTypes.string,
  }),
  noData: PropTypes.string,
  placeholder: PropTypes.string,
};

Autocomplete.defaultProps = {
  error: null,
  required: false,
  minChars: 1,
  onChange: () => {},
  onBlur: () => {},
  options: [],
  value: null,
  noData: null,
  placeholder: null,
};

export default Autocomplete;
/* eslint-enable react/jsx-props-no-spreading */
