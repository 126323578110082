/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Box,
} from 'rebass';

const Stepper = ({
  key,
  children,
  ...rest
}) => (
  <Flex
    py={[2, 0]}
    justifyContent="center"
    {...rest} // eslint-disable-line react/jsx-props-no-spreading
  >
    {children.map((step, index) => (
      <Box mx={[1, 2]} key={`${key}-${index}`}>
        {step}
      </Box>
    ))}
  </Flex>
);

Stepper.propTypes = {
  key: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

Stepper.defaultProps = {
  key: 'stepper',
};

export default Stepper;
