const buttonsCommon = {
  borderWidth: '1px',
  borderStyle: 'solid',
  fontFamily: 'Roboto',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0 0 5px 2px rgba(38, 38, 38, .3)',
  },
  '&:active': {
    borderColor: 'active',
    bg: 'active',
    color: 'dark',
  },
};

const card = {
  padding: [5, 6],
  boxShadow: ['none', 'card'],
  borderRadius: ['zero', 'card'],
  bg: 'card',
  marginBottom: [0, 4, 4],
  // height: ['100vh', 'auto'],
};
const cardWithLogo = {
  ...card,
  paddingTop: [8, 7, 7],
  marginTop: [0, 9, 9],
};

const logo = {
  display: 'block',
  position: 'absolute',
  top: '-114px',
  left: '50%',
  transform: 'translate(-50%, 0)',
  height: '102px',
};

const copyright = {
  margin: '10px auto',
  fontSize: 0,
  fontFamily: 'copyright',
  textAlign: 'center',
  // position: 'absolute',
  // left: '50%',
  // transform: 'translate(-50%, 0)',
  // bottom: [-4, -8, -8],
  // bottom: 2,
  minWidth: '100%',
};

const theme = {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [
    13, 16, 18, 20, 24, 29, 32, 48, 64, 110,
  ],
  colors: {
    primary: '#262626',
    secondary: '#D9D9D9',
    active: '#EBEBEB',
    disabled: '#EBEBEB',
    background: '#EBEBEB',
    card: '#ffffff',
    info: '',
    danger: '#FF0000',
    warning: '',
    success: '',
    light: '#ffffff',
    dark: '#262626',
  },
  space: [
    0, 7, 10, 20, 26, 34, 60, 80, 168, 120,
  ],
  fonts: {
    body: 'Roboto, system-ui, sans-serif',
    heading: 'Roboto, system-ui, sans-serif',
    copyright: 'Roboto, system-ui, sans-serif',
    input: 'Roboto, system-ui, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    card: '0 12px 16px rgba(0, 0, 0, .04), 0 4px 56px rgba(0, 0, 0, .04)',
  },
  variants: {
    card,
    cardWithLogo,
    logo,
    copyright,
  },
  text: {
  },
  radii: {
    button: '4px',
    card: ['0px', '10px', '10px'],
    zero: '0px',
  },
  buttons: {
    primary: {
      ...buttonsCommon,
      color: 'light',
      bg: 'primary',
      borderColor: 'primary',
    },
    secondary: {
      ...buttonsCommon,
      color: 'dark',
      bg: 'secondary',
      borderColor: 'secondary',
    },
    outlinePrimary: {
      ...buttonsCommon,
      color: 'primary',
      bg: 'white',
      borderColor: 'primary',
      '&:active': {
        borderColor: 'primary',
        bg: 'primary',
        color: 'light',
      },
    },
    underlinePrimary: {
      ...buttonsCommon,
      color: 'primary',
      bg: 'white',
      border: 'none',
      textDecoration: 'underline',
      '&:active': {
        bg: 'primary',
        border: 'none',
        color: 'light',
      },
    },
  },
};

export default theme;
