/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'rebass';
import Icon from './Icon';

const ThemeButton = ({
  icon,
  children,
  disabled,
  hidden,
  ...rest
}) => (
  <Button
    px="12px" // 12 (padding vero) + 18 (margin per icona!)
    py="14px"
    sx={{
      fontSize: 3,
      fontWeight: 'bold',
      borderRadius: 'button',
      '&:disabled': {
        // cursor: 'not-allowed',
        opacity: 0.4,
      },
      visibility: hidden ? 'hidden' : 'visible',
    }}
    disabled={disabled}
    {...rest}
  >
    <span style={{ margin: '0 18px', pointerEvents: 'none' }}>{children}</span>
    {icon && <span style={{ float: 'right', pointerEvents: 'none' }}><Icon name={icon} /></span>}
  </Button>
);

ThemeButton.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

ThemeButton.defaultProps = {
  icon: null,
  children: null,
  disabled: false,
  hidden: false,
};

export default ThemeButton;
