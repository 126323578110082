import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Flex,
  Heading,
} from 'rebass';

const ThemeSeparator = ({
  key,
  title,
}) => (
  <Flex my={[3, 5, 5]}>
    <Box pr={title ? '14px' : '0'} style={{ flexGrow: 1 }} key={`${key}-hrl`}>
      <hr style={{ borderColor: '#D9D9D9', borderStyle: 'solid' }} />
    </Box>
    {title && [
      <Box key={`${key}-title`}>
        <Heading color="dark" fontSize={2}>{title}</Heading>
      </Box>,
      <Box pl="14px" style={{ flexGrow: 1 }} key={`${key}-hrr`}>
        <hr style={{ borderColor: '#D9D9D9', borderStyle: 'solid' }} />
      </Box>,
    ]}
  </Flex>
);

ThemeSeparator.propTypes = {
  key: PropTypes.string,
  title: PropTypes.string,
};

ThemeSeparator.defaultProps = {
  key: 'separator',
  title: 'null',
};

export default ThemeSeparator;
