import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Heading,
  Image,
} from 'rebass';

const ThemeCard = ({
  logo,
  title,
  subtitle,
  children,
  ...rest
}) => {
  const variant = logo ? 'cardWithLogo' : 'card';

  const subLines = subtitle ? subtitle.split(/\\n/).map((line) => (
    <div key={`${line}`}>
      {line}
      <br />
    </div>
  )) : null;

  return (
    <Card
      variant={variant}
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
    >
      <div style={{ position: 'relative' }}>
        {logo && <Image variant="logo" src={logo} />}
        {title && (
          <Box py={3}>
            <Heading
              as="h1"
              fontSize={5}
              style={{ textAlign: 'center' }}
            >
              {title}
            </Heading>
          </Box>
        )}
        {subtitle && (
          <Box pb={3}>
            <Heading
              as="h5"
              // fontSize={5}
              style={{ textAlign: 'center' }}
            >
              {subLines}
            </Heading>
          </Box>
        )}
        {children}
      </div>
    </Card>
  );
};

ThemeCard.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

ThemeCard.defaultProps = {
  logo: null,
  title: null,
  subtitle: null,
  children: null,
};

export default ThemeCard;
