/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';

import {
  Flex,
  Box,
} from 'rebass';

import {
  Input,
  Select,
  Label,
} from '@rebass/forms';

import DatePicker from 'react-datepicker';
import DatePickerInput from './DatePickerInput';
import Message from './Message';

const createSelect = ({ options, ...rest }) => (
  <Select {...rest}>
    {options.map((o) => (
      <option key={o.key}>
        {o.value}
      </option>
    ))}
  </Select>
);

const createDatePicker = (props) => (
  <DatePicker
    minDate={props.minDate}
    maxDate={props.maxDate}
    customInput={<DatePickerInput error={props.error} />}
    renderCustomHeader={({
      date,
      decreaseMonth,
      increaseMonth,
      decreaseYear,
      increaseYear,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
      prevYearButtonDisabled,
      nextYearButtonDisabled,
    }) => (
      <Flex
        mx={10}
        my={10}
        justifyContent="center"
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            decreaseYear();
          }}
          disabled={prevYearButtonDisabled}
        >
          {'<<'}
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            decreaseMonth();
          }}
          disabled={prevMonthButtonDisabled}
        >
          {'<'}
        </button>
        <Message color="dark" text={`${new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date)} ${date.getFullYear()}`} />
        <button
          onClick={(e) => {
            e.preventDefault();
            increaseMonth();
          }}
          disabled={nextMonthButtonDisabled}
        >
          {'>'}
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            increaseYear();
          }}
          disabled={nextYearButtonDisabled}
        >
          {'>>'}
        </button>
      </Flex>
    )}
    {...props}
  />
);

const createNormalInput = (props) => (
  <Input {...props} />
);

const ThemeInput = (props) => {
  const {
    type,
    error,
    label,
    name,
  } = props;

  const { required, ...otherProps } = props;

  const innerProps = {
    ...otherProps,
    sx: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: (error ? 'danger' : 'secondary'),
      borderRadius: '6px',
      fontFamily: 'input',
      height: '48px',
      color: 'dark',
      '&::placeholder': {
        fontSize: '16px',
        fontFamily: 'input',
        color: 'dark',
        opacity: 0.5,
      },
    },
  };
  const generateInnerComponent = () => {
    if (type === 'select') return createSelect(innerProps);
    if (type === 'date') return createDatePicker(innerProps);
    return createNormalInput(innerProps);
  };

  return (
    <Box>
      <Flex sx={{ marginBottom: '8px' }}>
        <Label
          htmlFor={name}
          style={{
            lineHeight: '24px',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 'bold',
            width: 'auto',
          }}
        >
          {label}
          {required && '*'}
        </Label>
        {error && (
          <Box
            flexGrow={1}
            sx={{
              textAlign: 'right',
              color: 'danger',
              lineHeight: '24px',
              fontFamily: 'Roboto',
              fontSize: '14px',
            }}
          >
            {error}

          </Box>
        )}
      </Flex>
      {generateInnerComponent()}
    </Box>
  );
};

ThemeInput.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

ThemeInput.defaultProps = {
  type: 'input',
  required: false,
  error: null,
  options: [],
};

export default ThemeInput;
