import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Box, Heading } from 'rebass';
import Icon from './Icon';

const Message = ({
  color,
  bg,
  text,
  iconBefore,
  iconAfter,
  children,
}) => (
  <Flex>
    <Box
      p={3}
      py={4}
      textAlign="center"
      color={color}
      width="100%"
      sx={{
        borderRadius: '4px',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          zIndex: 0,
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: bg,
          opacity: 0.3,
        },
      }}
    >
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        {text && (
          <Heading as="h1" fontSize={1}>
            {iconBefore && <span style={{ pointerEvents: 'none', paddingRight: '5px' }}><Icon name={iconBefore} /></span>}
            {text}
            {iconAfter && <span style={{ pointerEvents: 'none', paddingLeft: '5px' }}><Icon name={iconAfter} /></span>}
          </Heading>
        )}
        {children}
      </Box>
    </Box>
  </Flex>
);

Message.propTypes = {
  color: PropTypes.string,
  bg: PropTypes.string,
  // text: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  iconBefore: PropTypes.string,
  iconAfter: PropTypes.string,
  children: PropTypes.node,
};

Message.defaultProps = {
  color: 'dark',
  bg: 'background',
  iconBefore: null,
  iconAfter: null,
  children: null,
};

export default Message;
