/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'rebass';

const Step = ({
  index,
  active,
  onClick,
  ...rest
}) => (
  <Box
    sx={{
      bg: (active ? 'primary' : 'disabled'),
      borderRadius: '50%',
      width: ['8px', '12px'],
      height: ['8px', '12px'],
      cursor: (onClick ? 'pointer' : 'default'),
    }}
    onClick={() => {
      if (onClick) onClick(index);
    }}
    {...rest}
  />
);

Step.propTypes = {
  index: PropTypes.number.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

Step.defaultProps = {
  active: false,
  onClick: null,
};

export default Step;
