/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Box } from 'rebass';
import { Label, Checkbox } from '@rebass/forms';

import Tooltip from './Tooltip';
import Text from './Text';

const ThemeCheckbox = ({
  id,
  name,
  label,
  active,
  tooltip,
  text,
  ...rest
}) => (
  <Box>
    <Flex
      justifyContent="space-between"
    >
      <Box>
        <Label
          sx={{
            alignItems: 'center',
            lineHeight: '24px',
            fontFamily: 'Roboto',
            fontSize: '16px',
          }}
        >
          <Checkbox
            id={id}
            name={name}
            {...rest}
          />
          {label}
        </Label>
      </Box>
      {(tooltip) && (
        <Box>
          <Tooltip
            tooltip={tooltip}
          />
        </Box>
      )}
    </Flex>
    {(text) && (
    <Flex>
      <Text color="grey" fontSize={0} fontStyle="italic" fontFamily="Roboto">
        {text}
      </Text>
    </Flex>
    )}
  </Box>
);

ThemeCheckbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  active: PropTypes.bool,
  tooltip: PropTypes.string,
  text: PropTypes.string,
};

ThemeCheckbox.defaultProps = {
  id: null,
  name: null,
  label: null,
  tooltip: null,
  text: null,
  active: false,
};

export default ThemeCheckbox;
