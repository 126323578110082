/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'rebass';

const Modal = ({
  visible,
  children,
  ...rest
}) => (
  <div className="modal" style={{ display: visible ? 'block' : 'none' }}>
    <Card
      variant="card"
      width={['100%', '600px']}
      mt={5}
      mx="auto"
      {...rest}
    >
      {children}
    </Card>
  </div>
);

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node,
};
Modal.defaultProps = {
  children: null,
};

export default Modal;
