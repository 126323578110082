import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Box } from 'rebass';
import { Label, Radio } from '@rebass/forms';

import Tooltip from './Tooltip';
import Text from './Text';

const RadioButtons = ({
  label,
  name,
  options,
  onClick,
  onChange,
  value,
  required,
  tooltip,
  text,
  error,
}) => (
  <Box>
    <Flex justifyContent="space-between">
      <Box>
        <Label
          htmlFor={name}
          style={{
            marginBottom: '8px',
            lineHeight: '24px',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 'bold',
            width: 'auto',
          }}
        >
          {label}
          {required && '*'}
        </Label>
      </Box>
      {(tooltip) && (
        <Box>
          <Tooltip
            tooltip={tooltip}
          />
        </Box>
      )}
      {error && (
      <Box
        flexGrow={1}
        sx={{
          textAlign: 'right',
          color: 'danger',
          lineHeight: '24px',
          fontFamily: 'Roboto',
          fontSize: '14px',
        }}
      >
        {error}
      </Box>
      )}
    </Flex>
    {(text) && (
      <Flex>
        <Text color="grey" fontSize={0} fontStyle="italic" fontFamily="Roboto">
          {text}
        </Text>
      </Flex>
    )}
    <Flex height="48px" mx={-2} my={1}>
      {options.map((radio) => (
        <Label
          key={`key-${radio.id}`}
          sx={{
            alignItems: 'center',
            borderRadius: '6px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: (error ? 'danger' : 'secondary'),
            px: '16px',
            mx: 2,
            lineHeight: '24px',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          <Radio
            name={name}
            id={radio.id}
            value={radio.id}
            onClick={onClick}
            onChange={onChange}
            checked={value?.id === radio?.id}
          />
          {radio.label}
        </Label>
      ))}
    </Flex>
  </Box>
);

RadioButtons.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  text: PropTypes.string,
  error: PropTypes.string,
};

RadioButtons.defaultProps = {
  label: null,
  options: [],
  onClick: () => {},
  onChange: () => {},
  value: null,
  required: false,
  tooltip: null,
  text: null,
  error: null,
};

export default RadioButtons;
