/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Box,
} from 'rebass';

const Divider = (props) => (
  <Box
    {...props}
    as="hr"
    sx={{
      bg: 'gray',
      border: 0,
      height: 1,
    }}
  />
);

export default Divider;
