// export default {
//   backendUri: process.env.REACT_APP_BACKEND_URI || 'http://localhost:3010',
//   authentication: {
//     clientId: process.env.REACT_APP_CLIENT_ID || '606191c4a6939341ac7459c5',
//     clientSecret: process.env.REACT_APP_CLIENT_SECRET || 'WebCheckinFrontSecret',
//     authUrl: process.env.REACT_APP_AUTH_URL || 'http://login:3000/oauth/token',
//   },
// };

export default {
  backendUri: process.env.REACT_APP_BACKEND_URI || 'http://localhost:3701',
  authentication: {
    clientId: process.env.REACT_APP_CLIENT_ID || '606191c4a6939341ac7459c5',
    clientSecret: process.env.REACT_APP_CLIENT_SECRET || 'WebCheckinFrontSecret',
    authUrl: process.env.REACT_APP_AUTH_URL || 'http://localhost:4001/oauth/token',
  },
};

// production
// export default {
//   backendUri: process.env.REACT_APP_BACKEND_URI || 'https://api.wci.guezzt.com',
//   authentication: {
//     clientId: process.env.REACT_APP_CLIENT_ID || '5d9c66754e801f5d585f8cee',
//     clientSecret: process.env.REACT_APP_CLIENT_SECRET || 'FrontendSecret',
//     authUrl: process.env.REACT_APP_AUTH_URL || 'https://login.guezzt.com/oauth/token',
//   },
// };
